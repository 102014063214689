
import { SsoProvider } from "@evercam/shared/types"
import ConnectWithSSOProviderButton from "@/components/users/ConnectWithSSOProviderButton"
export default {
  components: {
    ConnectWithSSOProviderButton,
  },
  props: {
    linkText: {
      type: String,
      default: () => "",
    },
    ssoProvider: {
      type: String,
      default: () => SsoProvider.Evercam,
    },
  },
}
